import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  partnerUrl,
  distributeUrl,
  memberUrl,
} from "../../utils/baseUrl";

import axios from "axios";
import Swal from "sweetalert2";

const AddMember = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      password: "",
      pltype: "",
    },
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [partnerID, setPartnerID] = useState("");
  const [user, setUser] = useState({});
  const [isEmail, setIsEmail] = useState(false);
  useEffect(() => {
    /*     var user = localStorage.getItem("id");
          console.log("user: ", user); */
    var user = localStorage.getItem("id");
    var partner = localStorage.getItem("partnerID");
    if (user != null) {
      setUserId(user);
      setPartnerID(partner);
    }
  }, []);

  const handleOnSubmit = (data) => {
    setLoading(true);
    //firstname,lastname,phone,email,password,pltype
    axios
      .post(`${distributeUrl}checkemailmember.php`, {
        pid: partnerID,
        email: data.email,
      })
      .then(function (response) {
        console.log(response.data);
        if (response.data.status === 1) {
          setIsEmail(true);
          setLoading(false);
          setUser(response.data);
        } else {
          //-------------
          axios
            .post(`${distributeUrl}addmember.php`, {
              firstname: data.firstname,
              lastname: data.lastname,
              phone: data.phone,
              email: data.email,
              password: data.password,
              userId: userId,
              partnerID: partnerID,
            })
            .then(function (response) {
              console.log(response.data);
              reset();
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500,
              });
              setIsEmail(false);
              setLoading(false);
            });
          //-------------else
        }
      });

    //---------------------
  };
  //rounded-sm bg-red-500 border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark
  //border-b border-stroke py-4 px-6.5 dark:border-strokedark
  return (
    <>
      <div className="">
        <div className="">
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="mb-4.5 flex flex-col gap-6 md:flex-row">
              <div className="mb-2 flex-1">
                <label>Firstname</label>
                <input
                  type="text"
                  name="firstname"
                  {...register("firstname", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.firstname && (
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    Enter firstname
                  </div>
                )}
              </div>
              {/* //firstname,lastname,phone,email,password,pltype */}
              <div className="mb-2 flex-1">
                <label>Lastname</label>
                <input
                  type="text"
                  name="lastname"
                  {...register("lastname", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.lastname && (
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    Enter lastname
                  </div>
                )}
              </div>
            </div>

            <div className="mb-2">
              <label>Phone</label>
              <input
                type="tel"
                name="phone"
                {...register("phone", {
                  required: true,
                })}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
              {errors.phone && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  Enter phone
                </div>
              )}
            </div>
            <div className="mb-4.5 flex flex-col gap-6 md:flex-row">
              <div className="mb-2 flex-1">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  {...register("email", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.email && (
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    Enter email
                  </div>
                )}
                {isEmail && (
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    Account already exists”, it means that the email address
                    you're trying to add, has been registered as an account
                    already
                  </div>
                )}
              </div>
              {/* //firstname,lastname,phone,email,password,pltype */}
              <div className="mb-2 flex-1">
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  {...register("password", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.password && (
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    Enter password
                  </div>
                )}
              </div>
            </div>
            {/* ----  */}

            {/* mpackage id,title,shortdetail,longdetail,price,period,unit,created_at */}
            <div className="flex justify-end">
              <button
                disabled={loading}
                type="submit"
                className="flex w-[200px] justify-center rounded bg-primary p-3 font-medium text-gray"
              >
                {loading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddMember;
