import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";

import {
  settingsTypeUrl,
  partnerUrl,
  distributeUrl,
  memberUrl,
} from "../../utils/baseUrl";

import { IoTrashOutline, IoCloseSharp } from "react-icons/io5";
import {
  HiOutlineBanknotes,
  HiMiniPencilSquare,
  HiMiniPlus,
  HiMiniEye,
  HiMiniEyeSlash,
} from "react-icons/hi2";

import { BsCart4 } from "react-icons/bs";

import { FiSave } from "react-icons/fi";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
const ViewMember = () => {
  const [loading, setLoading] = useState(false);
  const [memberData, setMemberData] = useState([]);
  const [userId, setUserId] = useState("");
  const [partnerID, setPartnerID] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 40;
  const pagesVisited = pageNumber * usersPerPage;
  function readMember(id, pid) {
    axios
      .get(`${distributeUrl}viewmember.php/${id}/${pid}`)
      .then(function (response) {
        console.log(response.data);
        setMemberData(response.data);
      });
  }
  useEffect(() => {
    /*     var user = localStorage.getItem("id");
      console.log("user: ", user); */
    var user = localStorage.getItem("id");
    var partner = localStorage.getItem("partnerID");
    if (user != null) {
      setUserId(user);
      setPartnerID(partner);
    }
    readMember(user, partner);
  }, []);

  //localhost:8080/appApi/settings/accounttype/deleteactype.php/1
  const deleteData = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${distributeUrl}deletemember.php/${id}`)
          .then(function (response) {
            console.log(response.data);
            readMember(userId, partnerID);
            // Swal.fire("Deleted!", "Your file has been deleted.", "success");
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Your work has been saved",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    });
  };

  const pageCount = Math.ceil(memberData.length / usersPerPage);
  const displayAllDatas = memberData
    .slice(pagesVisited, pagesVisited + usersPerPage)
    .map((item, index) => {
      return (
        <tr key={index}>
          <td className="border-b border-[#eee] dark:border-strokedark">
            <h5 className="font-medium text-black dark:text-white">
              {item?.firstname} {item?.lastname}
            </h5>
            {/*                       <p className="text-sm"> {item?.address}</p>
          <p className="text-sm"> {item?.phone}</p> */}
          </td>
          <td className="border-b border-[#eee] dark:border-strokedark">
            {item?.email}
          </td>
          <td className="border-b border-[#eee] dark:border-strokedark">
            {item?.phone}
          </td>
          <td className="border-b border-[#eee] dark:border-strokedark">
            <div className="flex items-center space-x-3.5">
              <button
                onClick={() => deleteData(item?.id)}
                className="hover:text-primary"
              >
                <IoTrashOutline size={22} />
              </button>
              {/*               <button
                onClick={() => deleteData(item?.id)}
                className="hover:text-primary"
              >
                {item?.isDelete === 1 ? (
                  <HiMiniEye size={22} />
                ) : (
                  <HiMiniEyeSlash size={22} />
                )}
              </button> */}
              <Link to={`editmember/${item?.id}`} className="">
                <HiMiniPencilSquare size={22} />
              </Link>
              <Link to={`vieworder/${item?.id}`} className="">
                <BsCart4 size={22} />
              </Link>
              {/*                         <Link to={`addsalary/${item?.id}`} className="">
              บันทึกเงินเดือน
            </Link> */}
            </div>
          </td>
        </tr>
      );
    });
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            ข้อมูลสมาชิก
          </h4>
          <div className="max-w-full overflow-x-auto">
            {/* --------  */}
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <th className="min-w-[200px] w-full font-medium text-black dark:text-white">
                    ชื่อ-สกุล
                  </th>
                  <th className="min-w-[200px] font-medium text-black dark:text-white">
                    อีเมล์
                  </th>
                  <th className="min-w-[200px] font-medium text-black dark:text-white">
                    โทรศัพท์
                  </th>
                  <th className="min-w-[100px] font-medium text-black dark:text-white">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* ---------  */}
                {displayAllDatas}

                {/* ---------  */}
              </tbody>
            </table>
            <div className="my-8">
              <ReactPaginate
                previousLabel={"ก่อนหน้า"}
                nextLabel={"ถัดไป"}
                pageCount={pageCount}
                pageRangeDisplayed={4}
                marginPagesDisplayed={2}
                onPageChange={changePage}
                containerClassName={
                  "flex items-center justify-center space-x-1"
                }
                pageClassName={
                  "px-4 py-2 rounded-full bg-gray text-gray-700 bg-gray-200 rounded-md hover:bg-graydark hover:text-white"
                }
                previousLinkClassName={
                  "flex items-center px-4 py-2 text-gray-500 bg-gray-300 rounded-md"
                }
                nextLinkClassName={
                  "px-4 py-2 font-bold text-gray-500 bg-gray-300 rounded-md hover:bg-graydark hover:text-white"
                }
                breakLabel="..."
                breakClassName="page-item"
                activeClassName=""
                /*                 pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                disabledClassName={""} */
                // activeClassName="active"
                // activeClassName=""
                renderOnZeroPageCount={null}
              />
            </div>
            {/* --------  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewMember;
