// const url = "https://dashboardgiftlikes.com/providerApi/";
// const url = "https://dashboardgiftlikes.com/appApi/";
// const url = "http://192.168.1.110:8080/appApi/";
// const url = "http://192.168.1.123:8080/appApi/";
// const url = "https://สระกระโจมรีสอร์ท.com/bookingApi/";
//const url = "http://192.168.1.113:8080/bookingApi/"; //192.168.1.111
//192.168.1.110/192.168.1.110/192.168.1.101/192.168.1.101/192.168.1.113
//C:\xampp\htdocs\appApl\settings\accounttype
//192.168.1.111

//const url = "http://192.168.1.116:8080/appApi/"; //192.168.1.116,192.168.1.116
//192.168.1.110/192.168.1.110/192.168.1.101/192.168.1.101
//C:\xampp\htdocs\appApl\settings\accounttype
const url = "https://giftlikesinfo.com/appApi/";
//const url = "http://192.168.1.110:8080/appApi/"; //192.168.1.116,192.168.1.116
export const pltype = 1;
export const settingsUrl = `${url}settings/`;
export const settingsTypeUrl = `${settingsUrl}accounttype/`;

export const govUrl = `${url}adverts/gov/`;
export const pvUrl = `${url}adverts/provider/`;
export const uUrl = `${url}adverts/unit/`;
//apiinfo
export const appinfoUrl = `${settingsUrl}apiinfo/`;
export const locationUrl = `${settingsUrl}location/`;
export const gfUserUrl = `${settingsUrl}useraccount/`;
export const gfIncomeUrl = `${settingsUrl}income/`;
export const gfcompanyUrl = `${settingsUrl}mycompany/`;
export const bankUrl = `${settingsUrl}bank/`;

export const occupationUrl = `${url}occupations/`;
export const mainOccUrl = `${url}occupations/main/`;
export const minorOccUrl = `${url}occupations/minor/`;

export const picSalepageUrl = `${url}salepage/picture/`;
export const videoSalepageUrl = `${url}salepage/video/`;
export const yuSalepageUrl = `${url}salepage/youtube/`;
export const pnSalepageUrl = `${url}salepage/partner/`;
export const disSalepageUrl = `${url}salepage/distribute/`;

export const mPackageUrl = `${url}package/major/`;
export const sPackageUrl = `${url}package/supplement/`;

export const partnerUrl = `${url}partner/`;
export const distributeUrl = `${url}distribute/`;
export const memberUrl = `${url}member/`;
export const advertUrl = `${url}advert/`;
export const logoUrl = `${url}logo/`;

export const buyPackageUrl = `${url}buypackage/`;
export const reportUrl = `${url}report/`;

//$target_dir = "../../assets/appinfo/";
export const imgAppInfoUrl = `${url}assets/appinfo/`;
export const imglocationUrl = `${url}assets/locations/`;

//$target_dir = "../../assets/occupation/main/";
export const imgmainOccUrl = `${url}assets/occupation/main/`;
export const imgminorOccUrl = `${url}assets/occupation/minor/`;
export const imgSalepageFileUrl = `${url}assets/salepage/picture/`;
export const videoSalepageFileUrl = `${url}assets/salepage/video/`;
//assets\salepage\pnsalepage
export const pnSalepageFileUrl = `${url}assets/salepage/pnsalepage/`;
export const disSalepageFileUrl = `${url}assets/salepage/dissalepage/`;

// $target_dir = "../../assets/advert/gov/";//video
export const imgGovUrl = `${url}assets/advert/gov/`;
export const imgPvUrl = `${url}assets/advert/provider/`;
export const imgUnUrl = `${url}assets/advert/unit/`;
export const imgLogoUrl = `${url}assets/logo/`;

/* export const roomTypeUrl = `${url}roomtype/`;
export const roomUrl = `${url}room/`;
export const checkinUrl = `${url}checkin/`;
export const expensesUrl = `${url}expenses/`; */

/* export const userUrl = `${url}users/`;
export const adminUrl = `${url}admin/`;
export const employeeUrl = `${url}employee/`;
export const facilityUrl = `${url}facility/`;
export const productUrl = `${url}product/`;
export const pricePersonUrl = `${url}priceaddperson/`;
export const priceBedUrl = `${url}priceaddbed/`; */
//apiinfo
/* export const loginUrl = `${url}login/`;
export const customerUrl = `${url}customer/`;
export const bookingUrl = `${url}booking/`;
export const checkInUrl = `${url}checkin/`;
export const imgUrl = `${url}assets/images/`;
export const imgThumbUrl = `${url}assets/images/thumbnail/`; */
/* export const gfcompanyUrl = `${settingsUrl}mycompany/`;
export const advertTypeUrl = `${settingsUrl}adverttype/`;
export const logoTypeUrl = `${settingsUrl}logotype/`;
export const bankUrl = `${settingsUrl}bank/`;
export const bankCompanyUrl = `${settingsUrl}companybank/`;

export const occupationUrl = `${url}occupations/`;
export const mainOccUrl = `${url}occupations/main/`;
export const minorOccUrl = `${url}occupations/minor/`;

export const picSalepageUrl = `${url}salepage/picture/`;
export const videoSalepageUrl = `${url}salepage/video/`;
export const yuSalepageUrl = `${url}salepage/youtube/`;
export const pnSalepageUrl = `${url}salepage/partner/`;
export const gfSalepageUrl = `${url}salepage/giftlikes/`;

export const mPackageUrl = `${url}package/major/`;
export const sPackageUrl = `${url}package/supplement/`;
export const buyPackageUrl = `${url}buypackage/`;

export const partnerUrl = `${url}partner/`;
export const distributeUrl = `${url}distribute/`;
export const introduceUrl = `${url}introduce/`;
export const advertUrl = `${url}advert/`;
export const logoUrl = `${url}logo/`;
export const statusUrl = `${url}status/`; */
//export const introduceaccount = `${url}introduce/`;
//offices\heros
/* export const officesMenusUrl = `${url}offices/menus/`;
export const officesHerosUrl = `${url}offices/heros/`;
export const officesPopularsUrl = `${url}offices/populars/`;
export const officesPopularsHeaderUrl = `${url}offices/popularheader/`;
export const officesCompanyUrl = `${url}offices/company/`;
export const officesDwProviderUrl = `${url}offices/downloadprovider/`;
export const officesFootersUrl = `${url}offices/footers/`;
export const officesPTUrl = `${url}offices/policyterm/`;
export const officesGalleryUrl = `${url}offices/gallery/`;
export const officesFaqUrl = `${url}offices/faq/`; */
//$target_dir = "../../assets/appinfo/";
/* export const imgAppInfoUrl = `${url}assets/appinfo/`;
export const imglocationUrl = `${url}assets/locations/`;
export const imgOfficeGalleryUrl = `${url}assets/office_gallery/`;
export const imgPackageUrl = `${url}assets/package/`;
export const imgFaqUrl = `${url}assets/faq/`;
export const imgBankUrl = `${url}assets/bank/`; */

//$target_dir = "../../assets/occupation/main/";
/* export const imgmainOccUrl = `${url}assets/occupation/main/`;
export const imgminorOccUrl = `${url}assets/occupation/minor/`;
export const imgSalepageFileUrl = `${url}assets/salepage/picture/`;
export const videoSalepageFileUrl = `${url}assets/salepage/video/`; */

//assets\salepage\pnsalepage
/* export const pnSalepageFileUrl = `${url}assets/salepage/pnsalepage/`;
export const gfSalepageFileUrl = `${url}assets/salepage/giftlikes/`; */

// $target_dir = "../../assets/advert/gov/";//video
/* export const imgGovUrl = `${url}assets/advert/gov/`;
export const imgPvUrl = `${url}assets/advert/provider/`;
export const imgUnUrl = `${url}assets/advert/unit/`;
export const imgLogoUrl = `${url}assets/logo/`;
export const imgOfficeUrl = `${url}assets/office/`; */

/* export const pltype = 1;
export const gfaccountUrl = `${url}gfaccount/`;
export const gfaccountUrl = `${url}gfaccount/`;
export const gfincome = `${url}gfincome/`;
export const allincome = `${url}allincome/`;
export const authUrl = `${url}gflogin/`;
export const videoUrl = `${url}video/`; */
/* export const gflaborUrl = `${url}labor/`;
export const gflaborlogoUrl = `${url}laborlogo/`;
export const partneraccount = `${url}partneraccount/`;
export const partnerincome = `${url}partnerincome/`;
export const centeraccount = `${url}center/`;
export const introduceaccount = `${url}introduce/`;
export const leaderaccount = `${url}leader/`;
export const disaccount = `${url}distributeraccount/`;
export const pvUrl = `${url}providers/`;
export const advertUrl = `${url}advert/`;
export const pvuserUrl = `${url}pvuser/`;
export const proviceappUrl = `${url}proviceapp/`;
export const mainOccUrl = `${url}mainoccupation/`;
export const minorOccUrl = `${url}minoroccupation/`;
export const salepage = `${url}salepage/`; */
//manager imgMinorUrl
/* export const imgAdUrl = `${url}images/advert/`;
export const userUrl = `${url}users/`;
export const imgProviceUrl = `${url}images/proviceapp/`;
export const imglaborUrl = `${url}images/labor/`;
export const imgOccUrl = `${url}images/occphoto/`;
export const imgPnUrl = `${url}images/pphoto/`;
export const imgMinorUrl = `${url}images/minorphoto/`;
export const imgSalepageUrl = `${url}images/salepage/`;
export const videoSalepageUrl = `${url}images/salepage/video/`; */
/* export const partneraccount = `${url}partneraccount/`;
export const partnercompany = `${url}partnercompanyinfo/`;
export const partnerinfo = `${url}partnerinfo/`;
export const distributorprofile = `${url}distributorprofile/`; */
//partnerinfo

/* export const disaccount = `${url}distributeraccount/`;
export const memberaccount = `${url}memberaccount/`;
export const gfincome = `${url}gfincome/`;

export const partnerincome = `${url}partnerincome/`;
export const distributeincome = `${url}distributeincome/`;
export const salepage = `${url}salepage/`;
export const dissalepage = `${url}dissalepage/`; */
// export const pnsalepage = `${url}pnsalepage/`;

/* export const bankUrl = `${url}bank/`;
export const authUrl = `${url}gflogin/`;
export const pnbankUrl = `${url}partnerbookbank/`;
export const disbankUrl = `${url}distributebank/`;

export const pnAuthUrl = `${url}pnlogin/`;
export const disAuthUrl = `${url}dislogin/`;
export const memberAuthUrl = `${url}providers/`;
export const userUrl = `${url}pvuser/`; */
// export const registerUrl = "http://localhost/providerApi/gflogin/";
//mainoccupation
/* export const mainOccUrl = `${url}mainoccupation/`;
export const minorOccUrl = `${url}minoroccupation/`;
export const imgOccUrl = `${url}images/occphoto/`;
export const imgPnUrl = `${url}images/pphoto/`;
export const imgPvUrl = `${url}images/user_avatar/`;
export const imgGalleryUrl = `${url}images/gallery/`;
export const imgDisUrl = `${url}images/dphoto/`;
export const imgMinorUrl = `${url}images/minorphoto/`;
export const imgSalepageUrl = `${url}images/salepage/`;
export const imgPnSalepageUrl = `${url}images/pnsalepage/`;
export const imgDisSalepageUrl = `${url}images/dissalepage/`;
export const imgProviceUrl = `${url}images/proviceapp/`;
export const imgAvUrl = `${url}images/advertapp/`;
export const packageUrl = `${url}package/`;
export const salepageUrl = `${url}salepage/`;
export const proviceappUrl = `${url}proviceapp/`;
export const avappUrl = `${url}advertapp/`; */
