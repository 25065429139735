import React from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import DefaultLayout from "./layout/DefaultLayout";
import {
  Home,
  Member,
  ChangePwd,
  Policy,
  SignIn,
  BankAccount,
  SalePage,
} from "./pages";
import {
  AddMember,
  EditMember,
  ViewMember,
  ViewOrderPackage,
} from "./components/member";

import {
  AddSalepage,
  ViewSalepage,
  PreviewSalepage,
  EditSalepage,
} from "./components/salepage";

const Layout = () => {
  return (
    <DefaultLayout>
      <Outlet />
    </DefaultLayout>
  );
};

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/member",
        element: <Member />, //AddMember, EditMember, Viewmember
        children: [
          {
            path: "",
            element: <ViewMember />,
          },
          {
            path: "addmember",
            element: <AddMember />,
          },
          {
            path: "editmember/:id",
            element: <EditMember />,
          },
          {
            path: "vieworder/:id",
            element: <ViewOrderPackage />,
          },
        ],
      },
      {
        path: "/salepage",
        element: <SalePage />, //AddSalepage, ViewSalepage, PreviewSalepage
        children: [
          {
            path: "",
            element: <ViewSalepage />,
          },
          {
            path: "addsalepage",
            element: <AddSalepage />,
          },
          {
            path: "editsalepage/:id",
            element: <EditSalepage />,
          },
          {
            path: "previewsalepage/:id",
            element: <PreviewSalepage />,
          },
        ],
      },
      {
        path: "/changepwd",
        element: <ChangePwd />,
      },
      {
        path: "/bankaccount",
        element: <BankAccount />,
      },
    ],
  },
  {
    path: "signin",
    element: <SignIn />,
  },
  {
    path: "/policy",
    element: <Policy />,
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
