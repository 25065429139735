import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  pnSalepageFileUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  pnSalepageUrl,
  picSalepageUrl,
  gfSalepageUrl,
  gfSalepageFileUrl,
  yuSalepageUrl,
  videoSalepageFileUrl,
  disSalepageUrl,
  disSalepageFileUrl,
} from "../../utils/baseUrl";
// pnSalepageFileUrl
import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Swal from "sweetalert2";

// import ReactPlayer from "react-player";
import ReactPlayer from "react-player/lazy";

import { HiOutlinePhone, HiOutlineMail } from "react-icons/hi";
import { BiGlobe } from "react-icons/bi";
// import { BsFacebook } from "react-icons/bs";
import { AiOutlineFacebook, AiOutlineLink } from "react-icons/ai";
import { FaLine, FaRegIdBadge } from "react-icons/fa";

const PreviewSalepage = () => {
  const { id } = useParams();
  const [datas, setDatas] = useState({});
  const [salepages, setSalePagess] = useState([]);
  const [dataApp, setDataApp] = useState([]);
  const saleurl = "/salepage/";
  const regisurl = "/register/";

  const [dataYu, setDataYu] = useState([]);
  const [dataVideo, setDataVideo] = useState([]);

  function readYuData() {
    axios.get(`${yuSalepageUrl}randomyu.php`).then(function (response) {
      console.log(response.data);
      setDataYu(response.data);
    });
  }
  function readVideoData() {
    axios.get(`${videoSalepageUrl}randmyvideo.php`).then(function (response) {
      console.log(response.data);
      setDataVideo(response.data);
    });
  }

  function readAppData() {
    axios.get(`${picSalepageUrl}read.php`).then(function (response) {
      console.log(response.data);
      setDataApp(response.data);
    });
  }
  //http://localhost:3000/previewsalepage/5
  function readDatas(id) {
    axios
      .get(`${disSalepageUrl}readsalepagebyid.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setDatas(response.data);
      });
  }

  useEffect(() => {
    readDatas(id);
    readAppData();
    readYuData();
    readVideoData();
    // readSalepages();
  }, []);

  /*   pnSalepageUrl,
  pnSalepageFileUrl, */

  console.log("You: ", dataYu);
  console.log("You 0: ", dataYu[0]);
  console.log("You 1: ", dataYu[1]);
  console.log("You: ", dataVideo);
  console.log("You 0: ", dataVideo[0]);
  console.log("You 1: ", dataVideo[1]);
  return (
    <>
      <div className="flex flex-col gap-10 md:w-1/2 mx-auto">
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <h4 className="mb-6 text-xl  text-center font-semibold text-black dark:text-white">
            Web Salepage
          </h4>
          {/* ---------  */}
          <div className="flex flex-col gap-2">
            {dataYu[0]?.url !== undefined ? (
              <div className="md:h-[300px]">
                <ReactPlayer
                  controls={true}
                  url={`${dataYu[0]?.url}`}
                  width="100%"
                  height="100%"
                />
              </div>
            ) : null}
            {dataVideo[0]?.video !== undefined ? (
              <div className="md:h-[300px]">
                <ReactPlayer
                  controls={true}
                  url={`${videoSalepageFileUrl}${dataVideo[0]?.video}`}
                  width="100%"
                  height="100%"
                />
              </div>
            ) : null}

            {datas.picture != "" ? (
              <>
                <img
                  src={`${disSalepageFileUrl}${datas.picture}`}
                  className="rounded-lg mb-0 w-[100%]"
                />
                <div className="flex gap-2 flex-col md:flex-row">
                  {datas?.fburl != "-" ? (
                    <>
                      <a
                        href={datas?.fburl}
                        target="_blank"
                        className="flex items-center flex-1 px-1"
                      >
                        <img
                          src={require("../../images/s02.png")}
                          className="w-10 h-10 mr-1 md:mr-4"
                        />{" "}
                        Facebook
                      </a>
                    </>
                  ) : null}
                  {datas?.lineurl != "-" ? (
                    <>
                      <a
                        href={datas?.lineurl}
                        target="_blank"
                        className="flex my-2 items-center flex-1 px-1"
                      >
                        <img
                          src={require("../../images/s03.png")}
                          className="w-10 h-10  mr-1 md:mr-4"
                        />{" "}
                        {datas?.lineurl}
                      </a>
                    </>
                  ) : (
                    <></>
                  )}
                  {datas?.phone !== "-" ? (
                    <>
                      <Link to={""} className="flex items-center flex-1 px-1">
                        <img
                          src={require("../../images/s05.png")}
                          className="w-10 h-10 mr-1 md:mr-2"
                        />{" "}
                        {datas?.phone}
                      </Link>
                    </>
                  ) : (
                    <></>
                  )}
                  {/* {datas?.fburl != null ?(<></>):(<></>)} */}
                </div>
              </>
            ) : null}
            {datas.picture2 != "" ? (
              <img
                src={`${disSalepageFileUrl}${datas.picture2}`}
                className="rounded-lg mb-0 w-[100%]"
              />
            ) : (
              ""
            )}
            {datas.picture3 != "" ? (
              <img
                src={`${disSalepageFileUrl}${datas.picture3}`}
                className="rounded-lg mb-0 w-[100%]"
              />
            ) : (
              ""
            )}
            {dataApp.map((item, index) => (
              <div key={index} className="">
                {item.photo !== "" ? (
                  <img
                    src={`${imgSalepageFileUrl}${item.photo}`}
                    className="rounded-lg mb-0 w-[100%]"
                  />
                ) : (
                  "-"
                )}
              </div>
            ))}
            {datas.picture == "" ? (
              <>
                <img
                  src={`${disSalepageFileUrl}${datas.picture}`}
                  className="rounded-lg mb-0 w-[100%]"
                />
                <div className="flex gap-2 flex-col md:flex-row">
                  {datas?.fburl != "-" ? (
                    <>
                      <a
                        href={datas?.fburl}
                        target="_blank"
                        className="flex items-center flex-1 px-1"
                      >
                        <img
                          src={require("../../images/s02.png")}
                          className="w-10 h-10 mr-1 md:mr-4"
                        />{" "}
                        Facebook
                      </a>
                    </>
                  ) : null}
                  {datas?.lineurl != "-" ? (
                    <>
                      <a
                        href={datas?.lineurl}
                        target="_blank"
                        className="flex my-2 items-center flex-1 px-1"
                      >
                        <img
                          src={require("../../images/s03.png")}
                          className="w-10 h-10  mr-1 md:mr-4"
                        />{" "}
                        {datas?.lineurl}
                      </a>
                    </>
                  ) : (
                    <></>
                  )}
                  {datas?.phone !== "-" ? (
                    <>
                      <Link to={""} className="flex items-center flex-1 px-1">
                        <img
                          src={require("../../images/s05.png")}
                          className="w-10 h-10 mr-1 md:mr-2"
                        />{" "}
                        {datas?.phone}
                      </Link>
                    </>
                  ) : (
                    <></>
                  )}
                  {/* {datas?.fburl != null ?(<></>):(<></>)} */}
                </div>
              </>
            ) : null}

            {dataYu[1]?.url !== undefined ? (
              <div className="md:h-[300px]">
                <ReactPlayer
                  controls={true}
                  url={`${dataYu[1]?.url}`}
                  width="100%"
                  height="100%"
                />
              </div>
            ) : null}
            {dataVideo[1]?.video !== undefined ? (
              <div className="md:h-[300px]">
                <ReactPlayer
                  controls={true}
                  url={`${videoSalepageFileUrl}${dataVideo[1]?.video}`}
                  width="100%"
                  height="100%"
                />
              </div>
            ) : null}
          </div>
          {/* ---------  */}
        </div>
      </div>
    </>
  );
};

export default PreviewSalepage;
