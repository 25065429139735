import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  imgmainOccUrl,
  picSalepageUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  videoSalepageFileUrl,
  pnSalepageUrl,
  pnSalepageFileUrl,
  disSalepageUrl,
  disSalepageFileUrl,
} from "../../utils/baseUrl";

import axios from "axios";

import toast, { Toaster } from "react-hot-toast";

import Swal from "sweetalert2";

import { IoTrashOutline, IoCloseSharp } from "react-icons/io5";
import {
  HiOutlineBanknotes,
  HiMiniPencilSquare,
  HiMiniPlus,
} from "react-icons/hi2";

import { FaRegEye } from "react-icons/fa";

import { FiSave } from "react-icons/fi";

import {
  BiGridHorizontal,
  BiGridAlt,
  BiSolidGrid,
  BiSolidGridAlt,
  BiXCircle,
  BiCopyAlt,
} from "react-icons/bi";

const ViewSalepage = () => {
  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const [userId, setUserId] = useState("");

  function readData(id) {
    axios
      .get(`${disSalepageUrl}getsalepage.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setDatas(response.data);
      });
  }

  useEffect(() => {
    var user = localStorage.getItem("id");
    console.log("user: ", user);
    setUserId(user);
    readData(user);
  }, []);
  /*   useCallback(() => {
      readData(userId);
      return () => {};
    }, []); */

  const deleteData = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${disSalepageUrl}delete.php/${id}`)
          .then(function (response) {
            console.log(response.data);
            readData(userId);
            // Swal.fire("Deleted!", "Your file has been deleted.", "success");
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Your work has been saved",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    });
  };
  return (
    <>
      <div className="">
        <div className="">
          <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            รายการส่งเสริมการขาย(Web Salepage)
          </h4>
          {/* ------------- */}

          {/* <h2>{userId}</h2> */}
          {/* locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at */}
          {datas.map((item, index) => (
            <div
              key={index}
              className="rounded-lg border border-[#EDEDED] shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1"
            >
              <div className="grid grid-cols-1 md:grid-cols-4 gap-2">
                {item.picture != "" ? (
                  <img
                    src={`${disSalepageFileUrl}${item.picture}`}
                    className="rounded-lg m-2 w-full md:w-[300px]"
                  />
                ) : (
                  ""
                )}
                {item.picture2 != "" ? (
                  <img
                    src={`${disSalepageFileUrl}${item.picture2}`}
                    className="rounded-lg m-2 w-full md:w-[300px]"
                  />
                ) : (
                  ""
                )}
                {item.picture3 != "" ? (
                  <img
                    src={`${disSalepageFileUrl}${item.picture3}`}
                    className="rounded-lg m-2 w-full md:w-[300px]"
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="rounded-lg border border-[#EDEDED] p-2 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1">
                <div className="mt-2 flex justify-center items-center md:justify-end">
                  <CopyToClipboard
                    text={item?.salepageurl}
                    onCopy={() => toast.success("Successfully created!")}
                  >
                    <button className="bg-gray-500 mr-4 hover:bg-gray-700 text-center py-1 px-4 rounded">
                      <BiCopyAlt size={22} />
                    </button>
                  </CopyToClipboard>

                  <button
                    // onClick={() => confirmDelete(item.id)}
                    onClick={() => deleteData(item.id)}
                    className="bg-red-500 mr-4 hover:bg-red-700 text-center py-1 px-4 rounded"
                  >
                    <IoTrashOutline size={22} />
                  </button>
                  <Link
                    to={`editsalepage/${item.id}`}
                    className="mr-1 bg-blue-500 hover:bg-blue-700 text-center py-1 px-4 rounded"
                  >
                    <HiMiniPencilSquare size={22} />
                  </Link>
                  <Link
                    to={`previewsalepage/${item.id}`}
                    className="mr-1 bg-blue-500 hover:bg-blue-700 text-center py-1 px-4 rounded"
                  >
                    <FaRegEye size={22} />
                  </Link>
                </div>
              </div>

              <Toaster />
            </div>
          ))}

          {/* ------------- */}
        </div>
      </div>
    </>
  );
};

export default ViewSalepage;
